import gql from "graphql-tag"
import { ApiObservable,InputApiObservable, ObservableField, ObservableSummaryItem } from "~/schemas/gen"
import { Observable_Data,Observable_Data_Bool_Exp } from "~/models/graphql"
import {from_uuid} from "~/utils/ulid";

export type ObservableDatum = Partial<Observable_Data>;

export type ApiObservableDataNode  = Partial<ApiObservable> & {
  type?: Partial<{
    name:string
    identifier:string
  }>
}

export const fragApiObservableDataFields = gql`
fragment ApiObservableFields on observable_data {
    ulid identifier fields price quantity value instant
    updated_time description  date_of
    type:observable_type {
        name identifier
    }
    units {
        uuid instrument_type
    }
    instrument {
        display_name uuid identifier_type instrument_type
    }
}
`


export type SymMappingObservable = InputApiObservable & {
    is_create?:boolean
    from_holding?:boolean
    is_search?:boolean
    search_term?:string
}



//Return the  unique id for a symbol mapping entry
export function symbolMappingIdentity(x:ApiObservable|InputApiObservable){
    return [x.identifier,
        x.fields?.[ObservableField.SYM_ALT_NAME] ?? "",
        x.fields?.[ObservableField.SYM_NAME_ONLY] ?? false,
        x.fields?.[ObservableField.SYM_URL] ?? false,
        x.description ?? ""
    ].join("\0")
}


export type SummaryItemKey = Pick<ObservableSummaryItem,"date_of"|"src_label"|"workflow_ulid">

export function summaryItemIdentity(x:SummaryItemKey):string|undefined{
    const params = new URLSearchParams();
    if(x.date_of) params.append("date_of",x.date_of)
    if(x.src_label) params.append("src_label", x.src_label)
    if(x.workflow_ulid) params.append("workflow_ulid",x.workflow_ulid)

    return params.toString()
}

export function parseSummaryItemIdentity(x:string|undefined):SummaryItemKey|undefined {
    if(!x) return
    let parts = new URLSearchParams(x)
    let obj:any = {}
    for(let k of ["date_of","src_label","workflow_ulid"]){
      if(!parts.has(k)) continue
      let v = parts.get(k)
      obj[k] =  v
    }
    return obj as SummaryItemKey
}


export const toApiObservable = (v:ObservableDatum) => {
    return {...v,
        type_id:v.observable_type?.identifier,
        id:from_uuid(v.ulid)
    } as ApiObservable
}
